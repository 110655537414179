import React from "react"
import Layout from "../components/layout"
import ImageModal from "../components/imageModal"

const ModalPage = (props) => (
  <Layout>

    <ImageModal show="true" />
     
  </Layout>
)

export default ModalPage